var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isDataLoaded)?_c('div',{staticClass:"tw-bg-white"},[_c('v-container',[(!_vm.currentTrips.length && !_vm.isTripsFindPending)?_c('v-row',{staticClass:"tw-my-1/3"},[_c('v-col',{staticClass:"text-center"},[_c('img',{staticClass:"tw-rounded-md tw-block tw-mx-auto",attrs:{"src":require("@/assets/svgs/create-a-trip/Create_Trip.svg"),"alt":"Create a Trip"}}),_c('h1',{staticClass:"tw-mt-2 tw-text-lg tw-font-semibold"},[_vm._v("Get Started")]),_c('h3',{staticClass:"tw-text-base tw-text-light-grey tw-mb-8"},[_vm._v(" Start planning your next group adventure by creating a new trip! ")])])],1):_vm._e(),_c('v-row',{staticClass:"no-gutters"},[_c('v-col',[_c('v-btn',{staticClass:"tw-tracking-normal",attrs:{"block":"","to":{ name: 'TripCreateRoute' },"rounded":"","x-large":"","color":"secondary","large":""}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Create a Trip ")],1)],1)],1),(_vm.currentTrips.length)?_c('v-row',[_c('v-col',{staticClass:"tw-mb-2"},[_c('div',{staticClass:"tw-text-base tw-font-figtree tw-text-charcoal",staticStyle:{"font-weight":"600","line-height":"24px","text-align":"left"}},[_vm._v(" Current Trips ")])])],1):_vm._e(),_vm._l((_vm.currentTrips),function(trip){return _c('v-row',{key:trip.id,staticClass:"tw-mb-4 tw-mx-0",attrs:{"ripple":""},on:{"click":function($event){return _vm.goToTrip(trip)}}},[_c('v-col',{staticClass:"tw-rounded-lg tw-py-3 tw-px-5 tw-cursor-pointer tw-bg-lighter-grey tw-border tw-border-solid tw-border-lightest-grey tw-shadow-custom-sm",class:{ 'j-premium': trip.isPremium },attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center"}},[(_vm.iconsLoaded)?_c('v-col',{attrs:{"cols":"3"}},[_c('v-img',{attrs:{"src":_vm.iconUrl(trip)}})],1):_vm._e(),_c('v-col',{staticClass:"text-left",attrs:{"cols":"7"}},[_c('h4',{staticClass:"tw-font-semibold tw-truncate",class:{
                'tw-text-dark-green-two': !trip.isPremium,
                'tw-text-white': trip.isPremium
              }},[_vm._v(" "+_vm._s(trip.title)+" ")]),_c('p',{staticClass:"tw-m-0 tw-text-xs",class:{
                'tw-text-dark-green-two': !trip.isPremium,
                'tw-text-white': trip.isPremium
              }},[_vm._v(" Date: "+_vm._s(_vm.getFormattedDateRange(trip.startDate, trip.endDate))+" ")])]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-icon',{staticClass:"tw-text-grey-one"},[_vm._v("mdi-arrow-right")])],1)],1)],1)],1)})],2),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"tw-text-l-grey tw-text-left tw-my-8 tw-text-sm tw-font-bold"},[_vm._v(" MY TRIPS ")]),_c('div',{staticClass:"tw-flex tw-pb-2 tw-items-center tw-relative tw-border-b tw-border-warm-grey-200 tw-cursor-pointer tw-pb-4",on:{"click":function($event){return _vm.$router.push({ name: 'PastTripsRoute' })}}},[_c('img',{staticClass:"tw-mr-3",attrs:{"src":require("@/assets/svgs/past-trips.svg"),"alt":"Past Trips"}}),_c('p',{staticClass:"tw-m-0 tw-text-black"},[_vm._v("Past Trips")]),_c('img',{staticClass:"tw-absolute tw-right-2",attrs:{"src":require("@/assets/svgs/premium/arrow-forward.svg"),"alt":"See"}})]),_c('div',{staticClass:"tw-flex tw-pb-2 tw-items-center tw-relative tw-border-b tw-border-warm-grey-200 tw-cursor-pointer tw-pb-4 tw-mt-4",on:{"click":function($event){return _vm.$router.push({ name: 'DeclinedTripsRoute' })}}},[_c('img',{staticClass:"tw-mr-3",attrs:{"src":require("@/assets/svgs/declined-trips.svg"),"alt":"Declined Trips"}}),_c('p',{staticClass:"tw-m-0 tw-text-black"},[_vm._v("Declined Trips")]),_c('img',{staticClass:"tw-absolute tw-right-2",attrs:{"src":require("@/assets/svgs/premium/arrow-forward.svg"),"alt":"See"}})])])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }