<template>
  <div class="trips-view tw-mb-5">
    <trip-list />
  </div>
</template>

<script>
import { DateTime } from "luxon";
import TripList from "@/components/trip/TripList.vue";
import jettyLogo from "@/assets/lets-jetty.svg";
import { mapActions } from "vuex";

export default {
  name: "TripsView",

  components: {
    TripList
  },
  computed: {
    sessionUser() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    ...mapActions({
      acceptTripInvite: "trip-invitation-accept/create"
    }),
    async submitRsvp(invitation) {
      try {
        const res = await this.acceptTripInvite({
          userId: this.sessionUser.id,
          tripInvitationUUID: invitation.uuid,
          status: invitation.selection
        });

        let hasDates = false;
        if (
          invitation.invitationData.trip.startDate &&
          invitation.invitationData.trip.endDate
        )
          hasDates = true;

        const now = DateTime.now();
        const start = DateTime.fromISO(this.sessionUser.createdAt);
        const diff = start.diff(now, "days").toObject();
        const count = Math.abs(Math.round(diff.days));
        let newUser = false;
        if (count <= 7) {
          newUser = true;
        }

        window.dataLayer.push({
          event: "rsvp_trip_invite",
          uuid: this.sessionUser ? this.sessionUser.uuid : null,
          signup_date: this.sessionUser
            ? DateTime.fromISO(this.sessionUser.createdAt).toISO()
            : null,
          new_user: newUser,
          verified_user: this.sessionUser
            ? this.sessionUser.isEmailVerified
            : false,
          sign_up_trip_id: this.sessionUser
            ? this.sessionUser.signupTripId
            : null,
          trip_id: invitation.invitationData.tripId,
          trip_invite_id: invitation.invitationData.id,
          owner_id: invitation.invitationData.ownerId,
          has_dates: hasDates,
          has_destination:
            invitation.invitationData &&
            invitation.invitationData.trip &&
            invitation.invitationData.trip.destinations &&
            invitation.invitationData.trip.destinations.length > 0,
          response_deadline: invitation.invitationData.rsvpDeadline,
          timestamp: DateTime.now().toISO(),
          rsvp_response: res.status
        });

        const tripHasSurvey = invitation.tripHasSurvey;

        if (
          res.status === "accepted" ||
          res.status === "maybe" ||
          res.status === "pending"
        ) {
          const routeName = tripHasSurvey
            ? "SurveyRoute"
            : "RSVPConfirmationYes";
          this.$router.push({
            name: routeName,
            params: {
              id: invitation.tripId,
              surveyId:
                invitation.invitationData.trip.surveys[
                  invitation.invitationData.trip.surveys.length - 1
                ]?.id
            }
          });
        } else if (res.status === "declined") {
          this.$router.push({
            name: "RSVPConfirmationNo",
            params: { id: invitation.tripId }
          });
        }
      } catch (error) {
        console.error("Error submitting RSVP:", error);
      }

      localStorage.removeItem("invitationPending");
      sessionStorage.setItem("fromInvitation", true);
    }
  },
  beforeMount() {
    this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
      button: false
    });

    if (localStorage.getItem("invitationPending")) {
      this.submitRsvp(JSON.parse(localStorage.getItem("invitationPending")));
    }
  },
  mounted() {
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "My Trips",
      bgColor: "#E6FFA1",
      fontColor: "#203848",
      tagline: null,
      iconOne: "mdi-bag-suitcase",
      iconTwo: jettyLogo
    });
  }
};
</script>

<style lang="scss">
.trips-view .page-title {
  font-family: "Bebas Neue", sans-serif;
}
</style>
