<template>
  <div class="tw-bg-white" v-if="isDataLoaded">
    <v-container>
      <!-- No Trips UI -->
      <v-row
        v-if="!currentTrips.length && !isTripsFindPending"
        class="tw-my-1/3"
      >
        <v-col class="text-center">
          <img
            src="@/assets/svgs/create-a-trip/Create_Trip.svg"
            alt="Create a Trip"
            class="tw-rounded-md tw-block tw-mx-auto"
          />
          <h1 class="tw-mt-2 tw-text-lg tw-font-semibold">Get Started</h1>
          <h3 class="tw-text-base tw-text-light-grey tw-mb-8">
            Start planning your next group adventure by creating a new trip!
          </h3>
        </v-col>
      </v-row>
      <!-- Create a Trip Button -->
      <v-row class="no-gutters">
        <v-col>
          <v-btn
            block
            :to="{ name: 'TripCreateRoute' }"
            rounded
            x-large
            color="secondary"
            large
            class="tw-tracking-normal"
          >
            <v-icon>mdi-plus</v-icon>Create a Trip
          </v-btn>
        </v-col>
      </v-row>
      <!-- Current Trips UI -->
      <v-row v-if="currentTrips.length">
        <v-col class="tw-mb-2">
          <div
            class="tw-text-base tw-font-figtree tw-text-charcoal"
            style="font-weight: 600; line-height: 24px; text-align: left"
          >
            Current Trips
          </div>
        </v-col>
      </v-row>
      <v-row
        v-for="trip in currentTrips"
        :key="trip.id"
        class="tw-mb-4 tw-mx-0"
        @click="goToTrip(trip)"
        ripple
      >
        <v-col
          class="tw-rounded-lg tw-py-3 tw-px-5 tw-cursor-pointer tw-bg-lighter-grey tw-border tw-border-solid tw-border-lightest-grey tw-shadow-custom-sm"
          :class="{ 'j-premium': trip.isPremium }"
          cols="12"
        >
          <v-row align="center">
            <v-col cols="3" v-if="iconsLoaded">
              <v-img :src="iconUrl(trip)" />
            </v-col>
            <v-col cols="7" class="text-left">
              <h4
                class="tw-font-semibold tw-truncate"
                :class="{
                  'tw-text-dark-green-two': !trip.isPremium,
                  'tw-text-white': trip.isPremium
                }"
              >
                {{ trip.title }}
              </h4>
              <p
                class="tw-m-0 tw-text-xs"
                :class="{
                  'tw-text-dark-green-two': !trip.isPremium,
                  'tw-text-white': trip.isPremium
                }"
              >
                Date: {{ getFormattedDateRange(trip.startDate, trip.endDate) }}
              </p>
            </v-col>
            <v-col cols="2">
              <v-icon class="tw-text-grey-one">mdi-arrow-right</v-icon>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12">
          <p
            class="tw-text-l-grey tw-text-left tw-my-8 tw-text-sm tw-font-bold"
          >
            MY TRIPS
          </p>
          <div
            class="tw-flex tw-pb-2 tw-items-center tw-relative tw-border-b tw-border-warm-grey-200 tw-cursor-pointer tw-pb-4"
            @click="$router.push({ name: 'PastTripsRoute' })"
          >
            <img
              src="@/assets/svgs/past-trips.svg"
              class="tw-mr-3"
              alt="Past Trips"
            />
            <p class="tw-m-0 tw-text-black">Past Trips</p>
            <img
              src="@/assets/svgs/premium/arrow-forward.svg"
              class="tw-absolute tw-right-2"
              alt="See"
            />
          </div>
          <div
            class="tw-flex tw-pb-2 tw-items-center tw-relative tw-border-b tw-border-warm-grey-200 tw-cursor-pointer tw-pb-4 tw-mt-4"
            @click="$router.push({ name: 'DeclinedTripsRoute' })"
          >
            <img
              src="@/assets/svgs/declined-trips.svg"
              class="tw-mr-3"
              alt="Declined Trips"
            />
            <p class="tw-m-0 tw-text-black">Declined Trips</p>
            <img
              src="@/assets/svgs/premium/arrow-forward.svg"
              class="tw-absolute tw-right-2"
              alt="See"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import feathersClient from "../../feathers-client.js";
import { FormattedDateRange } from "@/mixins/FormattedDateRange.js";
import { DESIGNS } from "@/enums/designs.js";
export default {
  name: "TripList",
  mixins: [FormattedDateRange],
  data() {
    return {
      trips: [],
      designs: DESIGNS,
      isDataLoaded: false,
      iconsLoaded: false
    };
  },
  computed: {
    currentTrips() {
      return this.trips.filter(this.isCurrentTrip).sort(this.sortByDate);
    },
    isTripsFindPending() {
      // Assuming you still use the store for checking if trips are being fetched
      return this.$store.state["me/trip"].isFindPending;
    }
  },
  methods: {
    iconUrl(trip) {
      let invitation = trip.trip_invitation;
      if (
        !trip.isPremium ||
        !invitation ||
        invitation.template.toLowerCase() === this.designs.DEFAULT
      ) {
        return require(`@/assets/svgs/my_trips/${trip.icon}`);
      } else if (
        invitation.template.toLowerCase() === this.designs.SIMPLE ||
        invitation.template.toLowerCase() === this.designs.SADDLE ||
        invitation.template.toLowerCase() === this.designs.GREEN_PALM
      ) {
        return require(
          `@/assets/svgs/custom-trip-icons/${invitation.template.toLowerCase()}.png`
        );
      } else {
        return require(
          `@/assets/svgs/custom-trip-icons/${invitation.template.toLowerCase()}.svg`
        );
      }
    },
    async goToTrip(trip) {
      try {
        const res = await this.$store.dispatch("trip/get", [
          trip.id,
          {
            query: {
              include: "users,trip_invitation"
            }
          }
        ]);
        if (res.active && res.users) {
          let member = res.users.filter(
            (user) => user.id === this.$store.state.auth.user.id
          )[0];
          let rsvp = member.trip_invite.status;
          if (rsvp === "declined") {
            this.$router.push({
              name: "CrewEditRoute",
              params: {
                id: trip.id,
                userId: member.id
              }
            });
          } else {
            this.goTo(trip);
          }
        } else {
          this.goTo(trip);
        }
      } catch {
        this.goTo(trip);
      }
    },
    goTo(trip) {
      this.$store.commit("meta/setCurrentTrip", trip);
      this.$router.push({ name: "TripViewRoute", params: { id: trip.id } });
    },
    isCurrentTrip(trip) {
      const currentDate = DateTime.now().toISODate();
      const isOrganizer = trip.organizerId === this.currentUserId;
      const rsvpStatus = trip.trip_invite.status;
      return (
        !trip.isCanceled &&
        !["declined"].includes(rsvpStatus) &&
        !trip.isArchived &&
        (trip.endDate ? trip.endDate > currentDate : true) &&
        (isOrganizer ||
          ["accepted", "maybe", "pending"].includes(rsvpStatus)) &&
        trip.active
      );
    },
    sortByDate(a, b) {
      // Sort by TBD dates first, then by chronological order
      if (!a.startDate) return -1;
      if (!b.startDate) return 1;
      return new Date(b.startDate) - new Date(a.startDate);
    },
    async fetchAndLogUserTrips() {
      try {
        const userId = this.$store.state.auth.user.id;

        const response = await feathersClient.service("users").get(userId, {
          query: {
            include: `trip_invite,trip`
          }
        });

        this.trips = response.trips;
        this.isDataLoaded = true;

        const tripPromises = response.trips.map((trip) =>
          this.$store.dispatch("trip/get", [
            trip.id,
            {
              query: {
                include: "trip_invitation"
              }
            }
          ])
        );

        const tripsDetailed = await Promise.all(tripPromises);

        for (const t of tripsDetailed) {
          for (const trip of this.trips) {
            if (t.id === trip.id) {
              trip.trip_invitation = t.trip_invitation;
            }
          }
        }
        this.iconsLoaded = true;
      } catch (error) {
        console.error("Error fetching user trips:", error);
      }
    }
  },

  beforeMount() {
    this.fetchAndLogUserTrips();
  }
};
</script>
